import React from "react";

function Checkout() {
  return (
    <div className="text-white max-w-screen-xl mx-auto flex items-center justify-center">
      Payment Gateway will Intergrate Soon...
    </div>
  );
}

export default Checkout;
